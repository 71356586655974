import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Nomination } from '../models/nomination/nomination.model';
import { AuthService } from './auth.service';
import { AddNomination } from '../models/nomination/add-nomination.model';


@Injectable()
export class NominationService {
    private readonly http = inject(HttpClient);
    private readonly authService = inject(AuthService);


    getAll(): Observable<Nomination[]> {
        return this.authService.getEmployeeId().pipe(
            switchMap(userId => this.http.get<Nomination[]>(`${environment.urls.api}nomination/for-user/${userId}`))
        );
    }


    create(nomination: AddNomination): Observable<Nomination> {
        return this.http.post<Nomination>(`${environment.urls.api}nomination`, nomination);
    }


    getReport(from: Date, to: Date): Observable<Blob> {
        return this.http.post<Blob>(`${environment.urls.api}nomination/get-report`,
            { from, to }, {
            responseType: 'blob' as 'json'
        }).pipe(
            tap(data => {
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = 'raport.csv';
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            })
        );
    }


    getCount(): Observable<number> {
        return this.authService.getEmployeeId().pipe(
            switchMap(userId => this.http.get<number>(`${environment.urls.api}nomination/count/${userId}`))
        );
    }

}
